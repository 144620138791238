import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {StoryService} from "../gallery-backend/story.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProjectDetails} from "../project-details";
import {SessionService} from "../core/session.service";
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ProjectComments} from "../project-comments";
import {BackendService} from "../gallery-backend/backend.service";

declare var $ :any;

@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.css']
})
export class ViewProjectComponent implements OnInit{

  project:ProjectDetails;
  body:any;
  projectUrl:string;
  sProjectUrl:any;
  showTextEditor:boolean=false;
  comment:ProjectComments;
  comments:Array<ProjectComments>;

  @ViewChild('editor', { static: false }) myInput: ElementRef;



  constructor(private service:BackendService, private router:Router, private renderer:Renderer2, private storyService:StoryService, private route: ActivatedRoute, private session:SessionService, private domSanitizationService: DomSanitizer, private chRef:ChangeDetectorRef) {

    this.route.paramMap.subscribe(params=> {

        if(this.session.project && params.get("projectKey") == this.session.project.key) {
          this.project = this.session.getProject();
          this.fetchProject();
          this.getProjectComments();
          this.chRef.detectChanges();

      } else {
          this.session.galleryListService$.subscribe(()=>{

            this.service.getProjectDetails(params.get("projectKey")).execute((project)=>{
              this.project = project;
              this.fetchProject();
              this.getProjectComments();
              this.chRef.detectChanges();
            })
          })

      }
    });



  }


  private fetchProject(){
    if(this.project) {
      this.projectUrl = this.project.ipynbFile ? this.project.storyUrl : this.project.purl;

      this.sProjectUrl = this.domSanitizationService.bypassSecurityTrustResourceUrl(this.projectUrl);
    }
    // this.storyService.getStory(this.project.ipynbFile? this.project.storyUrl: this.project.purl)
    //   .subscribe((data) => {
    //     var doc = new DOMParser().parseFromString(data, "text/html");
    //     let a = doc.getElementsByTagName("body")[0];
    //
    //     a.getElementsByTagName("img")[0].setAttribute("src", '_domSanitizationService.bypassSecurityTrustUrl('+a.getElementsByTagName("img")[0].getAttribute('src')+")");
    //     a.outerHTML = a.outerHTML.replace("<img src=", "<img src='_domSanitizationService.bypassSecurityTrustUrl('");
    //     this.body = a.outerHTML;
    //   });
  }

  public config ={ placeholder: '', tabsize: 2, height: 100, uploadImagePath: '', toolbar:
      [ // [groupName, [list of button]] ['misc', ['codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['bold',
        'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']], ['para', ['style', 'ul', 'ol',
        'paragraph', 'height']], ['insert', ['table', 'picture', 'link', 'video',
        'hr']] ], fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS',
      'Courier New', 'Roboto', 'Times'] }


  giveFeedback(){

    if(!this.session.getFirebaseUser()){
      alert("Please login to add comments");
      return;
    }

    this.showTextEditor=true;
    this.comment = new ProjectComments();
    this.comment.projectId = this.project.key;
    this.chRef.detectChanges();

    this.getProjectComments();
  }

  getProjectComments(){
      new Promise(resolve =>
        this.session.authTokenSet2$.subscribe(()=>{
          this.makeCommentCall();
          resolve(true);
        })
      );


  }

  makeCommentCall(){
    this.service.getProjectComments(this.project.key).execute((res)=>{
      this.comments = res.items;
      this.chRef.detectChanges();
    })
  }

  // editComment(comment){
  //
  //   this.showTextEditor=true;
  //   this.comment= comment;
  //   this.myInput.nativeElement.scrollIntoView();
  //   this.chRef.detectChanges();
  // }

  acceptComment(comment){
    comment.accepted = !comment.accepted;
    this.chRef.detectChanges();

    this.service.acceptProjectComments(comment.id, comment.accepted).execute(()=>{
      this.makeCommentCall();
      if(comment.accepted)
        alert("Congratulations on accepting the comment! You have earned 5 points");

      else {
        alert("Reverted your acceptance");
      }
    });
  }

  getAcceptLabel(comment){

    return comment.accepted?'Accepted':'Accept';
  }
  showAcceptButton(comment){
    return this.project.editable && comment.feedback;
  }

  deleteComment(comment){
    let yes = confirm("Are you sure you want to delete this comment?");
    comment.markedForDeletion=true;

    if(yes){
      this.service.addModifyProjectComments(comment).execute(()=>{
        this.makeCommentCall();
        alert("Your comment is marked for deletion");
      });
    }

  }

  ngOnInit () {


  }

  commentSubmit(){
    this.showTextEditor=false;
    this.chRef.detectChanges();

    this.service.addModifyProjectComments(this.comment).execute(()=>{
      this.makeCommentCall();
      alert("Added your comment");
      this.chRef.detectChanges();
    });


  }

}
