import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SessionService} from "../core/session.service";
import {FirebaseUserModel} from "../core/user.model";
import {BackendService} from "../gallery-backend/backend.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-cohort-member',
  templateUrl: './cohort-member.component.html',
  styleUrls: ['./cohort-member.component.css']
})
export class CohortMemberComponent implements OnInit {

  private cohortKey:string;
  private school:string;
  private semester:string;
  private courseId:string;
  private display:boolean = false;

  constructor(private session:SessionService, private cd:ChangeDetectorRef, private service:BackendService,private route: ActivatedRoute) {


  }

  ngOnInit() {

    this.route.paramMap.subscribe(params=>{
    this.session.authTokenSet$.subscribe((res)=> {
        this.cohortKey= params.get("cohortKey");

      this.session.mbccUserService.subscribe(
        () => {
          this.service.addCohortMember(this.cohortKey).execute((cohort) =>{
            this.semester = cohort.semester;
            this.school = cohort.school;
            this.courseId = cohort.courseId;
            this.display=true;
            this.cd.detectChanges();
          }, err =>{
            alert("Sorry could not add your emailId. Please try again")
          })
        });

      });

    });

  }

}

//usage https://talent.mobibootcamp.com/member-group/ahVzfmRhdGEtc2NpZW5jZS0xODU2MTRyEwsSBmNvaG9ydBiAgICc_6aZCQw
