/// <reference path="../../../node_modules/@types/gapi/index.d.ts" />
/// <reference path="../../../node_modules/@types/gapi.client/index.d.ts" />
import { Component, Input, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { BackendService } from '../gallery-backend/backend.service';
import { Router, Params} from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {NgBusyModule} from 'ng-busy';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SessionService } from '../core/session.service';
import {NgbModal, NgbActiveModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


import { Subject }    from 'rxjs';



@NgModule({
  imports: [
    BrowserModule,
    NgBusyModule,
    BrowserAnimationsModule,
    FormsModule]
})

@Component({
  selector: 'app-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.css'],
  providers:[BackendService]
})
export class GalleryListComponent implements OnInit {


  searchParams:object;
  projects:object;
  selected:boolean;
  searchText:string;
  dbText:string;
  busy: Subscription;
  waiting:boolean = true;
  displaySearch:boolean=true;
  displayDatabaseSearch:boolean=false;
  _groupMembers:boolean;
  userRoles:Array<string>;

  cohorts:Array<Object>=[];


  @Input('title')
  title:string;

  @Input('userId')
  userId:string;


  // Observable boolean source
  projectList = new Subject<any>();

  // Observable boolean stream
  projectList$ = this.projectList.asObservable();

  constructor( private zone: NgZone, private modalService: NgbModal,public activeModal: NgbActiveModal, private service: BackendService, private chRef: ChangeDetectorRef, private router: Router, private session: SessionService) {
    this.session.setProject(null);
  }

  @Input('userProjects')
  set userProjects(userProjects: boolean) {
    // this flow comes from user profile page

    this.displaySearch=false;

    let filter = "level=beginner";

    if(userProjects){

      if(this.userId){
        filter += ',OTHER_USER_LIST='+ this.userId;
      } else {
        filter += ",USER_LIST_ONLY=true";
      }
    }
    this.getProjects(filter);
  }



  @Input()
  set groupMembers(groupMembers: boolean) {
    // this flow comes from user profile page
    this.displaySearch=false;

    this._groupMembers=groupMembers;
    let filter = "level=beginner";
    if(groupMembers){
      if(!this.userId)
        filter += ",GROUP_LIST=true";
      else{
        filter += ",GROUP_LIST="+ this.userId;
      }
    }
    this.getProjects(filter);
  }

  getProjects(filter){

        this.waiting = true;
        this.chRef.detectChanges();
        this.service.getProjects({
          filter:filter,
          limit:50
        }).then((resp) => {
          this.projectList.next(resp.result);
        });

    this.projectList$.subscribe( (projects)=>{
      this.projects = projects.results;
      this.userRoles= projects.roles;
      this.waiting=false;
      this.chRef.detectChanges();
    }, (err)=>{
      this.waiting=false;
      this.chRef.detectChanges();
    })

  }


  ngOnInit() {

    this.cohorts = this.session.getStudentCohorts();

    if(this.cohorts == null) {
      this.session.studentCohort$.subscribe(() => {
        this.displayDatabaseSearch = true;
        this.cohorts = this.session.getStudentCohorts();
        this.chRef.detectChanges();
      });
    } else {
      this.displayDatabaseSearch = true;
    }


    if(this.router.url.endsWith("/login")){
      this.displaySearch=false;
    }

    if(this.router.url.endsWith("list") || this.router.url.endsWith("/login") || this.router.url.endsWith("/")){
      this.getFeaturedProjects();
    }
  }

  getFeaturedProjects(){
    this.service.getFeaturedProjects().subscribe((data) => {
      this.waiting=false;
      this.projects = data.results;
      this.chRef.detectChanges();
    });
  }

  fetchProject(project){
    this.session.setProject(project);
    this.zone.run(() => {

      let newRelativeUrl = this.router.createUrlTree(['/view-project/' + project.key]);
      let baseUrl = window.location.href.replace(this.router.url, '');

      window.open(baseUrl + newRelativeUrl, '_blank');
      // this.router.navigate(['/view-project', project.key])
    });
  }

  ngOnDestroy() {
    if(this.busy)
      this.busy.unsubscribe();
  }

  // openProjectUrlWindow(project){
  //   window.open(project.purl, "_blank");
  // }

  // open() {
  //
  //   const modalRef = this.modalService.open(ModalComponent); //Added Modal Component here
  //
  //   modalRef.componentInstance.src = "abc";//anything u wish to pass to modal component @Input
  //
  //
  //   modalRef.result.then((result) => {
  //     console.log(result);
  //     console.log('closed');
  //   }).catch( (result) => {
  //     console.log(result);
  //     console.log('cancelling');
  //   });
  //
  // }



  onSelected(i){


    if(i==0){
      this.getFeaturedProjects();
      return;
    }

    i= i-1;

    let filter = "level=beginner,";

    this.dbText='';

    if(this.cohorts[i]["domain"] == null) {
       filter +=  "course=" + this.cohorts[i]["courseId"] + ",semester=" + this.cohorts[i]["semester"] + ",school=" + this.cohorts[i]["school"];
    } else {
      filter += "domain=" + this.cohorts[i]["domain"];
    }

    this.getProjects(filter)

  }

  getProjectLabel(project){

    if(project && project.platforms && project.platforms.includes('android')){
      return 'Download App'
    }

    return 'View Analysis'
  }

  dbSearch(){
    if(this.dbText == null || this.dbText.length==0)
      return;

    this.dbText= this.dbText.toLowerCase();

    let filter = "dbSearch="+this.dbText;
    this.getProjects(filter);

  }

  detectReturn(e){
    if (e.keyCode == 13) {
      this.dbSearch();
    }
  }

}
