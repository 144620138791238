import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BackendService } from '../gallery-backend/backend.service';
import { SessionService } from '../core/session.service';
import {Router, NavigationStart} from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@NgModule({
  imports: [
    FormsModule]
})

@Component({
  selector: 'app-gallery-form',
  templateUrl: './gallery-form.component.html',
  styleUrls: ['./gallery-form.component.css']
})
export class GalleryFormComponent implements OnInit {

  project:any;

  cohorts:Array<Object>= null;
  displayAnalyticsDetails:boolean=true;
  summaryLabel:string="Insights Derived";


  constructor(private route: ActivatedRoute, private cd: ChangeDetectorRef, private service: BackendService, private router: Router, private session: SessionService) {

    this.initProject();
    this.route.paramMap.subscribe(params=> {
       if(params.get("projectKey") && this.session.project && params.get("projectKey") == this.session.project.key){
         if(this.session.getProject() != null){
           this.project = this.session.getProject();
         }
       }

      this.cd.detectChanges();
    });


    }


  initProject(){

    this.project = {
      id: new Date().getTime(),
      projectName: '',
      projectDescription: '',
      insights: '',
      imageUrl: '',
      pUrl: '',
      purl:'',
      technologies: [],
      platforms:[],
      otherModules: '',
      openDataUrl: '',
      level: 'beginner',
      learningResources: [],
      groupMembers:[],
      groupMember:'',
      resource: '',
      userlike: false,
      likeCount: 0,
      ipynbFile:true
    }

}

  ngOnInit() {

    if(this.project == null) {
      this.initProject()
    } else {

      if (this.project.learningResources == null ) {
        this.project.learningResources = [];
      }
      if (this.project.groupMembers == null ) {
        this.project.groupMembers = [];
      }
      this.project.resource='';
      this.project.groupMember='';
      //for some reason it comes as purl instead of pUrl from backend so this fix
      this.project.pUrl = this.project.purl;
      if(this.project.platforms.includes('android')){
        this.displayAnalyticsDetails = false;
        this.summaryLabel = "App Summary"
      } else {
        this.displayAnalyticsDetails = true;
        this.summaryLabel = "Insights Derived"
      }

      this.cd.detectChanges();

    }


    this.cohorts = this.session.getStudentCohorts();

    if(this.cohorts == null)
      this.session.studentCohort$.subscribe(() =>{
        this.cohorts = this.session.getStudentCohorts();
      })

    if (this.project.course == null && this.cohorts && this.cohorts.length > 0) {
      this.onSelected(0);
    }

  }


  addResource(){

    console.log("add resource" + this.project.resource);

    if(this.project.resource != null && this.project.resource.trim().length >0) {
      this.project.learningResources.push(this.project.resource);
      this.cd.detectChanges();
    }
  }

  addTeamMember(){
    this.project.groupMembers.push(this.project.groupMember);
    this.cd.detectChanges();
  }

  removeResource(resource){
    this.project.learningResources.splice(this.project.learningResources.indexOf(resource), 1);
    this.cd.detectChanges();
  }

  removeGroupMember(groupMember){
    this.project.groupMembers.splice(this.project.groupMembers.indexOf(groupMember), 1);
    this.cd.detectChanges();
  }


  onSubmit() {
    if((this.project.id == null && this.project.key == null) || this.project.insights == null){
      alert("Your project details are malformed. Cannot add. Please reload the page and enter the values again");
    } else {
      this.service.addProject(this.project).execute((r)=>{
        alert("added your project!");
        this.initProject();
        this.router.navigate(['/list']);
      }, (error) =>{
        alert("Sorry unable to add your project at this time")
      })
    }

  }

  toggleVisibility(e){
    this.project.privateProject= e.target.checked;
  }

  onSelected(i){

    this.project.school = this.cohorts[i]["school"];
    this.project.course = this.cohorts[i]["courseId"];
    this.project.semester = this.cohorts[i]["semester"];

  }

  onPlatformSelected(target){

    if(this.project == null){
      this.initProject();
    }
    if(target.value.indexOf('android') != -1) {
      this.displayAnalyticsDetails = false;
      this.summaryLabel="App Summary";
    } else {
      this.displayAnalyticsDetails = true;
      this.summaryLabel = "Insights Derived";
    }
    this.cd.detectChanges();
  }


}
