import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { UserService } from '../core/user.service';
import { FirebaseUserModel } from '../core/user.model';
import {SessionService} from "../core/session.service";

@Injectable()
export class BypassUser implements Resolve<FirebaseUserModel> {

  constructor(public userService: UserService, private router: Router, private session:SessionService) { }

  resolve(route: ActivatedRouteSnapshot) : Promise<FirebaseUserModel> {



    return new Promise((resolve, reject) => {

      return resolve(new FirebaseUserModel());
    })
  }
}
