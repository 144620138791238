import { Injectable } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { AuthService } from './auth.service';
import {SessionService} from "./session.service";

@Injectable()
export class UserService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    public session:SessionService
  ){
  }


  getCurrentUser(){
    if(this.session.user != null){
      return new Promise((resolve) =>{
        return resolve(this.session.user);
      });
    }

    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().onAuthStateChanged((user)=>{

        if (user) {
          user.getIdToken(/* forceRefresh */ true).then((idToken) => {

            let tokenObject:any;
            tokenObject = {
              access_token: idToken
            };

            this.session.idToken = idToken;

            gapi.auth.setToken(tokenObject);

            this.session.authTokenSet.next(true);
            this.session.authTokenSet2.next(true);


            try {
              gapi.auth2.getAuthInstance().signIn();
            }catch (e){
              console.log("auth error 1")
            }

          }).catch((error) => {
            //this.authService.doLogout();
            try {
              gapi.auth2.getAuthInstance().signIn();
            } catch (e){
              console.log("auth error 2")
            }
            console.log("token rejected");

          });

          this.session.user = user;
          resolve(user);

        } else {

          this.session.authFailed.next(true);
          reject('No user logged in');
        }
      })
    })
  }

  updateCurrentUser(value){
    return new Promise((resolve, reject) => {
      var user = firebase.auth().currentUser;

      user.updateProfile({
        displayName: value.name,
        photoURL: user.photoURL
      })
    })
  }


}
