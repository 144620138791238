import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SessionService} from "../core/session.service";

@Injectable({
  providedIn: 'root'
})
export class StoryService {

  constructor(private http: HttpClient, private session:SessionService) { }


  getStory(storyUrl:string) {
    const headers = new Headers({'Content-Type': 'text/html', 'Authorization':'Basic xxxx'});
    return this.http.get(storyUrl, {responseType: 'text'});
  }

  saveDataFile(dataFile:string, fileName:string, contentType:string){
    let auth = 'Bearer ' + this.session.idToken;

    var headers_object = new HttpHeaders();
    headers_object.append('Content-Type', contentType);
    headers_object.append("Authorization", this.session.idToken);

    // const httpOptions = {
    //   headers: headers_object
    // };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  contentType
   //     'Authorization': auth
      })
    };


    const url = 'https://www.googleapis.com/upload/storage/v1/b/files.mobibootcamp.com/o?uploadType=media&name=' +fileName;
    return this.http.post(url,dataFile,httpOptions)
  }
}
