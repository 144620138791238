import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      let values = Object.values(it);

      for(let i=0; i<values.length; i++){
        if(values[i].toString().toLowerCase().includes(searchText))
          return true;
      }
      return false;
    });
  }
}
