export class Userprofile {

  constructor(
    public name: string,
    public username: string,
    public location: string,
    public bio:string,
    public linkedInUrl:string,
    public skills:string,
    public jobSearch:boolean,
    public startMonth:string,
    public eligibility:number,
    public highestDegree:string,
    public pursuingDegree:string,
    public phone:string,
    public roles:Array<string>,
    public showProfilePic:boolean,
    public recommended:boolean,
    public recommendersCount:number,
    public teachingAssistant:boolean
  ) {  }

}
