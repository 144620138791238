import {ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BackendService} from "../gallery-backend/backend.service";
import {Router} from "@angular/router";
import {SessionService} from "../core/session.service";

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.css']
})
export class RecommendationComponent implements OnInit {

  @Input('userId')
  userId:string;

  @Input('recommended')
  recommended:boolean ;

  @Input('recommendersCount')
  recommendersCount:number = 0;

  comment:string;

  showText:boolean;

  constructor( private service: BackendService, private chRef: ChangeDetectorRef) {

  }


  ngOnInit() {
  }

  recommend(){

    if(this.recommended){
      this.recommended = false;
      this.recommendersCount--;
    } else {
      this.recommended = true;
      this.recommendersCount++;
    }
    this.chRef.detectChanges();
    this.sendFeedback();
  }

  giveFeedback(){
    this.showText = true;
    this.chRef.detectChanges();
  }

  sendFeedback(){
    this.service.recommendUser(this.userId, this.recommended, this.comment).execute(()=>{
      alert("Submitted. Thank you!");
    });
  }
}
