import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from "@angular/router";
import { AngularFireAuth } from 'angularfire2/auth';
import { UserService } from '../core/user.service';
import {SessionService} from "./session.service";


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
    private session:SessionService

  ) {}

  canActivate(): Promise<boolean>{

    if(this.session.getFirebaseUser() != null){
      return new Promise((resolve) =>{
        return resolve(true);
      });
    }
    return new Promise((resolve, reject) => {

      this.userService.getCurrentUser()
        .then(user => {

          return resolve(false);
        }, err => {
  
          return resolve(true);
        })
    })
  }
}
