import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { AuthService } from '../core/auth.service'
import { SessionService } from '../core/session.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {FirebaseUserModel} from "../core/user.model";


@Component({
  selector: 'page-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent {

  loginForm: FormGroup;
  errorMessage: string = '';




  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private session:SessionService,
    private chRef:ChangeDetectorRef

  ) {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['',Validators.required]
    });
  }

  tryFacebookLogin(){
    this.authService.doFacebookLogin()
      .then(res => {
        this.setUserInfo(res);
      })
  }

  tryTwitterLogin(){
    this.authService.doTwitterLogin()
      .then(res => {
        this.setUserInfo(res);
      })
  }

  tryGoogleLogin(){
    this.authService.doGoogleLogin()
      .then(res => {
        this.setUserInfo(res);
      })
  }

  tryMicrosoftLogin(){
    this.authService.doMicrosoftLogin()
      .then(res => {
        this.setUserInfo(res);
      })
  }

  setUserInfo(res){
    let user = new FirebaseUserModel()
    user.image = res.user.photoURL;
    user.name = res.user.displayName;
    user.provider = res.user.providerData[0].providerId;
    user.email = res.user.email;


    this.session.setFirebaseUser(user);
    this.router.navigate(['/list']);
  }

  tryLogin(value){
    this.authService.doLogin(value)
      .then(res => {
        this.router.navigate(['/list']);
      }, err => {
        console.log(err);
        this.errorMessage = err.message;
      })
  }
}
