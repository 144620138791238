import {Component, ChangeDetectorRef, OnInit, NgZone} from '@angular/core';
import { SessionService } from './core/session.service';
import {FirebaseUserModel} from "./core/user.model";
import {BackendService} from "./gallery-backend/backend.service";
import {forkJoin} from "rxjs/observable/forkJoin";
import {Router} from "@angular/router";





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Talent Gallery!';

  user:FirebaseUserModel;
  private showUploadButton: boolean;
  private showSpinner:boolean;
  private desktop:boolean;

  private x:Array<boolean>; // this array  is to circumvent a strange behavior of two components being added on using *ngIf

  ngOnInit(){


      if (window.screen.width > 1000) {
        this.desktop = true;
      }

    console.log("test1");
    this.getStudentsCohorts();

    this.service.mbccUser$.subscribe((firebaseUser)=>{
      this.user = firebaseUser;
      this.x=[];
      this.chRef.detectChanges();
    })


    this.service.authFailed$.subscribe((authFailed)=>{
      this.x=[true];
      this.chRef.detectChanges();
    })

  }

  addProject(){

      this.zone.run(() => {
        this.router.navigate(['/add']);
      });

  }

  constructor(
    public service: SessionService,  private zone:NgZone, private router:Router,  public chRef: ChangeDetectorRef, private backend:BackendService
  ) {



  }


  getStudentsCohorts(){

    let galleryListLoaded = () =>
      new Promise(resolve =>
        this.service.galleryListService$.subscribe(()=>{
          resolve(true);
        })
      );

    let authCompleted = () =>
      new Promise(resolve =>
        this.service.authTokenSet$.subscribe(()=>{
          this.showSpinner=true;
        resolve(true);
        })
      );

    let allServicesLoaded = forkJoin([
      galleryListLoaded(),
      authCompleted()]
    );

    allServicesLoaded.subscribe(val => {

      this.service.galleryServiceAndAuthCompleted =true;
      this.backend.getStudentCohorts().then((resp) => {
                let cohorts = resp.result.items;
                this.service.setStudentCohorts(cohorts);
                if(cohorts.length > 0 && cohorts[0].domain == null)
                  this.showUploadButton = true;
                this.showSpinner=false;
                this.chRef.detectChanges();
              });
    });



  }
}
