import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { FirebaseUserModel } from '../core/user.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  project:any = null;

  userProfile:any = null;

  idToken:string;

  apisLoaded:boolean = false;

  studentCohorts:Array<String>;

  firebaseUser:FirebaseUserModel = null;

  galleryServiceAndAuthCompleted:boolean=false;

  // Observable boolean source
  authFailed = new Subject<boolean>();

  // Observable boolean stream
  authFailed$ = this.authFailed.asObservable();

  // Observable boolean source
  mbccUser = new Subject<any>();

  // Observable boolean stream
  mbccUser$ = this.mbccUser.asObservable();

  // Observable boolean source
  userProfileService = new Subject<boolean>();

  // Observable boolean stream
  userProfileService$ = this.userProfileService.asObservable();

  // Observable boolean source
  mbccUserService = new Subject<boolean>();

  // Observable boolean stream
  mbccUserService$ = this.mbccUserService.asObservable();



  // Observable boolean source
  galleryFormService = new Subject<boolean>();

  // Observable boolean stream
  galleryFormService$ = this.galleryFormService.asObservable();


  // Observable boolean source
  galleryListService = new Subject<boolean>();

  // Observable boolean stream
  galleryListService$ = this.galleryListService.asObservable();


  // Observable boolean source
  userInfoService = new Subject<boolean>();

  // Observable boolean stream
  userInfoService$ = this.userInfoService.asObservable();


  // Observable boolean source
  authTokenSet = new Subject<any>();

  // Observable boolean stream
  authTokenSet$ = this.authTokenSet.asObservable();

  // Observable boolean source
  authTokenSet2 = new Subject<any>();

  // Observable boolean stream
  authTokenSet2$ = this.authTokenSet2.asObservable();

  // Observable boolean source
  studentCohort = new Subject<boolean>();

  // Observable boolean stream
  studentCohort$ = this.studentCohort.asObservable();

  user: firebase.User | any;

  setProject(project){
      this.project = project;
  }

  getProject(){
    return this.project;
  }

  setUserProfile(userProfile){

    this.userProfile=userProfile;
  }

  getUserProfile(){
      return this.userProfile
  }

  setFirebaseUser(firebaseUser){

    this.mbccUser.next(firebaseUser);
    this.firebaseUser=firebaseUser;
  }

  getFirebaseUser(){
    return this.firebaseUser;
  }

  getStudentCohorts(){
    return this.studentCohorts;
  }

  setStudentCohorts(cohorts){
    this.studentCohorts = cohorts;
    this.studentCohort.next(true);
  }




}
