import {ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {ProjectDetails} from "src/app/project-details";
import {SessionService} from "../core/session.service";
import {BackendService} from "../gallery-backend/backend.service";
import {Router} from "@angular/router";

@Component({
  selector: 'project-footer',
  templateUrl: './project-footer.component.html',
  styleUrls: ['./project-footer.component.css']
})
export class ProjectFooterComponent implements OnInit {


  @Input('project')
  project:ProjectDetails;

  @Input('userRoles')
  userRoles:Array<string>;

  @Input("displaySearch")
  displaySearch:boolean;

  @Input("groupMembers")
  groupMembers:boolean;

  refreshing:boolean;



  constructor(private session:SessionService, private chRef:ChangeDetectorRef,private service:BackendService, private zone:NgZone, private router:Router) {

  }

  ngOnInit() {


  }

  likeProject(project){

    if(!this.session.getFirebaseUser()){
      alert("Please login to like the project");
      return;
    }

    project.userlike = !project.userlike;

    if(project.userlike){
      project.likeCount++;
    } else {
      project.likeCount--;
    }

    this.chRef.detectChanges();

    this.service.likeProject(project.key, project.userlike).execute(()=>{
      console.log("success")
    });
  }


   updateUrl(project){
    if(project)
     project.profileUrl="assets/user-solid.svg";
   }

  editProject(project){

    this.session.setProject(project);

    this.zone.run(() => {
      this.router.navigate(['/add', project.key]);
    });
  }


  deleteProject(project){

    if(confirm("Are you sure you want to delete? Once deleted you cannot restore")){

      this.chRef.detectChanges();

      this.service.deleteProject(project.key).execute(()=>{
        //TODO remove project after delete
        alert("Project deleted.");
      }, (err)=>{
        alert("Sorry there is an error. Try again after some time")
      });
    }


  }


  refreshProject(project){

    this.refreshing = true;
    this.chRef.detectChanges();
    this.service.refreshProject(project.purl).execute(()=>{
      this.refreshing= false;
      this.chRef.detectChanges();
      alert("Submitted for building your story!");
    });
  }

  markPrivate(project){

    project.privateProject = !project.privateProject;
    this.service.markPrivate(project.key, project.privateProject).execute(()=>{
      alert("Changed your settings");
      this.chRef.detectChanges();
    }, (err)=>{
      alert("Sorry there is an error. Try again after some time")
    });
  }



}
