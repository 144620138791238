import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { GalleryListComponent } from './gallery-list/gallery-list.component';
import { GalleryFormComponent } from './gallery-form/gallery-form.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {PictureChangeComponent} from './picture-change/picture-change-component';
import {CohortComponent} from "./cohort/cohort.component";
import {CohortMemberComponent} from "./cohort-member/cohort-member.component";
import {UploadFileComponent} from "./upload-file/upload-file.component";
import {ViewProjectComponent} from "./view-project/view-project.component";
import {AppComponent} from "./app.component";
import {BypassUser} from "./user/bypass.user";

export const rootRouterConfig: Routes = [
  { path: '', redirectTo: 'list', pathMatch: 'full' },
  { path: 'p/:projectKey', component: PictureChangeComponent,  resolve: { data: UserResolver}},
  { path: 'list/:userId', component: UserComponent,  resolve: { data: UserResolver}},
  { path: 'list', component: GalleryListComponent  },
  { path: 'view-project/:projectKey', component: ViewProjectComponent, resolve: { data: BypassUser}},
  { path: 'view-project', redirectTo:'/list'},


  { path:'editProfile', component:UserProfileComponent, resolve:{data:UserResolver}},
  { path: 'add/:projectKey', component: GalleryFormComponent , resolve: { data: UserResolver}},
  { path: 'add', component: GalleryFormComponent , resolve: { data: UserResolver}},
  { path: 'user', component: UserComponent,  resolve: { data: UserResolver}},
  { path: 'cohort', component: CohortComponent , resolve: { data: UserResolver} },
  { path: 'uploadFile', component: UploadFileComponent , resolve: { data: UserResolver} },
  { path: 'member-group/:cohortKey', component: CohortMemberComponent , resolve: { data: UserResolver}}

];


