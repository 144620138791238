import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StoryService} from "../gallery-backend/story.service";
import {BackendService} from "../gallery-backend/backend.service";
import {SessionService} from "../core/session.service";
import {forkJoin} from "rxjs/observable/forkJoin";

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent {
  form: FormGroup;
  loading: boolean = false;
  waitingforUserfiles:boolean = false;
  waitingForAllUserfiles:boolean = false;

  uploadedFileId:string;
  allUserFiles:any;
  allFiles:any;
  file:any;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(private fb: FormBuilder, private service:StoryService, private session:SessionService, private backendService:BackendService, private chRef:ChangeDetectorRef) {
    this.createForm();

    if(this.session.galleryServiceAndAuthCompleted){
      this.getUserFiles();
      this.getAllFiles();
      return;
    }

    let galleryListLoaded = () =>
      new Promise(resolve =>
        this.session.galleryListService$.subscribe(()=>{
          resolve(true);
        })
      );

    let authCompleted = () =>
      new Promise(resolve =>
        this.session.authTokenSet$.subscribe(()=>{
          resolve(true);
        })
      );

    let allServicesLoaded = forkJoin([
      galleryListLoaded(),
      authCompleted()]
    );

    allServicesLoaded.subscribe(val => {
      this.session.galleryServiceAndAuthCompleted=true;
      this.getUserFiles();
      this.getAllFiles();
    });
  }

  getUserFiles(){
    this.waitingforUserfiles = true;
    this.backendService.managerUserFiles(null, "list", null, null , null).execute((list)=> {
      this.allUserFiles = list.items;
      this.waitingforUserfiles = false;
      this.chRef.detectChanges();
    });
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description:['', Validators.required],
      datafile: null
    });
  }

  onFileChange(event) {

    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];

      reader.readAsArrayBuffer(this.file);

      reader.onload = () => {

        this.form.get('datafile').setValue({
          name: this.file.name,
          value: reader.result
        })

        if(this.form.get('name').value == null || this.form.get('name').value == "")
          this.form.get('name').setValue(this.file.name);

      };
    }
  }

  onSubmit() {
    const formModel = this.form.value;
    this.loading = true;

    this.backendService.managerUserFiles(formModel.name, "add", formModel.description, this.file.type, null ).execute((list)=> {

      this.allUserFiles = list.items;

      this.saveToStorage(formModel);

    }, (err) =>{
        alert("Could not upload at this time. Please try again.")
    })


  }

  saveToStorage(formModel){
    this.service.saveDataFile(formModel.datafile.value, formModel.name, this.file.type).subscribe((res)=>{
      this.loading= false;
      this.chRef.detectChanges();

      this.uploadedFileId = "http://files.mobibootcamp.com/" + res["name"];
      alert("Successfully uploaded!");
      this.form.reset();

    }, (err) =>{
      this.loading= false;
      this.chRef.detectChanges();


      if(err.error.error.code == 401){
        alert("There is a file with the same name. Change the name and upload again.")
      } else {

        // this file could be uploaded by another user. So we do not want to delete it from storage
        this.backendService.managerUserFiles(formModel.name, "remove", formModel.description, null, false ).execute((list)=> {
          this.allUserFiles = list;

          this.chRef.detectChanges();
          this.saveToStorage(formModel);

        });

        alert("Could not upload the file at this time. Please try again");
      }

    });
  }

  clearFile() {
    this.form.get('datafile').setValue(null);
    this.fileInput.nativeElement.value = '';
    this.form.reset();
  }

  deleteFile(id){
    let yes = confirm("Are you sure you want to delete this file? Once deleted, we cannot retrieve it.")
    if(yes){

      this.backendService.managerUserFiles(id, "remove", null , null, true).execute((list)=> {
        this.allUserFiles = list.items;
        this.chRef.detectChanges();
      });
    }
  }

  getAllFiles(){
    this.waitingForAllUserfiles = true;
      this.backendService.getAllFiles().execute((list)=> {
        this.allFiles = list.items;
        this.waitingForAllUserfiles = false;
        this.chRef.detectChanges();
      });

  }


}
