import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { GalleryListComponent } from './gallery-list/gallery-list.component';
import { GalleryFormComponent } from './gallery-form/gallery-form.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { UserResolver } from './user/user.resolver';
import { rootRouterConfig } from './app.routes';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner,faEdit, faTrash, faComment, faSync, faEyeSlash, faEye, faThumbsUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import {faThumbsUp as regThumbs} from '@fortawesome/free-regular-svg-icons';

import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { Angular2FontawesomeModule } from 'angular2-fontawesome/angular2-fontawesome'
import { FilterPipe} from './gallery-list/filter.pipe';
import { UserLoginComponent } from './user-login/user-login.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { HttpClientModule } from '@angular/common/http';
import { PictureChangeComponent } from './picture-change/picture-change-component';
import {ModalComponent} from './modal/modal.component';
import { CohortComponent } from './cohort/cohort.component';
import { CohortMemberComponent } from './cohort-member/cohort-member.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ViewProjectComponent } from './view-project/view-project.component';
import { ProjectFooterComponent } from './project-footer/project-footer.component';
import {BypassUser} from "./user/bypass.user";
import { RecommendationComponent } from './recommendation/recommendation.component';
import { NgxSummernoteModule } from 'ngx-summernote';





@NgModule({
  declarations: [
    AppComponent,
    GalleryListComponent,
    GalleryFormComponent,
    UserProfileComponent,
    RegisterComponent,
    LoginComponent,
    FilterPipe,
    UserComponent,
    UserLoginComponent,
    PictureChangeComponent,
    ModalComponent,
    CohortComponent,
    CohortMemberComponent,
    UploadFileComponent,
    ViewProjectComponent,
    ProjectFooterComponent,
    RecommendationComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    Angular2FontawesomeModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    NgxGalleryModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    NgxSummernoteModule
  ],
  entryComponents:[ModalComponent],
  providers: [AuthService, UserService, UserResolver, BypassUser, AuthGuard, NgbActiveModal],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(faSpinner);
    library.add(faEdit);
    library.add(faTrash);
    library.add(faComment);
    library.add(faSync);
    library.add(faEyeSlash);
    library.add(faEye);
    library.add(faThumbsUp);
    library.add(regThumbs);
    library.add(faSearch);
  }
}







