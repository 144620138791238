import { NgZone, Injectable } from '@angular/core';
import { SessionService } from '../core/session.service';
import { HttpClient } from '@angular/common/http';

declare var gapi: any;

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private session:SessionService, private zone: NgZone, private http: HttpClient) {


    this.loadClient().then(() => {

      this.loadGallery().then(() =>{
        this.loadUserService().then(() =>{

          this.session.userProfileService.next(true);
          this.session.mbccUserService.next(true);
          this.session.galleryFormService.next(true);
          this.session.galleryListService.next(true);
          this.session.userInfoService.next(true);
          this.session.apisLoaded = true;
        })
      })

    });

  }


  loadGallery(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.zone.run(() => {
        gapi.client.load("gallery", 'v1', undefined, "https://data-science-185614.appspot.com/_ah/api").then(function(resp){
         resolve();

       },
       function(resp){
         reject();
       });
      });
    });
  }

  loadUserService(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.zone.run(() => {
        gapi.client.load("userService", 'v1', undefined, "https://data-science-185614.appspot.com/_ah/api").then(function(resp){
            resolve();

          },
          function(resp){
            reject();
          });
      });
    });
  }



  loadClient(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.zone.run(() => {
        gapi.load('client', {
          callback: resolve,
          onerror: reject,
          timeout: 1000, // 5 seconds.
          ontimeout: reject
        });
      });
    });
  }

  echo(){
    return gapi.client.gallery.echo();
  }


  updateUserProfile(userDetails):any{


    return gapi.client.userService.updateUserProfile({userDetails:JSON.stringify(userDetails)});
  }

  getUserProfile():any{
    return gapi.client.userService.getUserProfile();
  }

  setUserInfo(userInfo):any{
      return gapi.client.userService.setUserInfo({userInfo: JSON.stringify(userInfo)});
  }


  getOtherUserProfile(key):any{
    return gapi.client.userService.getOtherUserProfile({userKey:key});
  }

  addModifyCohort(cohort){
    return gapi.client.userService.addModifyCohort({cohort:JSON.stringify(cohort)});
  }


  addProject(projectDetails):any {
    return gapi.client.gallery.addProject({projectDetails:JSON.stringify(projectDetails)});
  }

  getProjects(searchParams):any {
    return gapi.client.gallery.galleryService.getProjectDetails({
      filter:searchParams.filter,
      limit:searchParams.limit
    });

  }

  getStudentCohorts() {
    return gapi.client.gallery.getStudentCohorts();
  }

  addCohortMember(key) {
    return gapi.client.userService.addCohortMember({'cohortId':key});
  }


  getFeaturedProjects(): any{
    let projects = this.http.get("https://storage.googleapis.com/eda-stories/featuredProjects.json", {responseType: 'json'});
    return projects;
  }

  refreshProject(pUrl){
    return gapi.client.gallery.extractStory({pUrl:pUrl})
  }

  deleteProject(key){
    return gapi.client.gallery.deleteProject({projectKey:key})
  }

  markPrivate(key, privateProject){
    var projectMarkings = "privateProject=" + privateProject;
    return gapi.client.gallery.markProject({projectKey:key, projectMarkings: projectMarkings})
  }

  markFeatured(key, featuredProject){
    var projectMarkings = "featuredProject=" + featuredProject;
    return gapi.client.gallery.markProject({projectKey:key, projectMarkings: projectMarkings})
  }

  exportFeatured(){
    return gapi.client.gallery.exportFeatured()
  }

  likeProject(projectId, liked:boolean):any{
      return gapi.client.gallery.likeProject({projectKey: projectId, like: liked});
  }

  saveSelectedPicture(projectKey, image, fileId){
    return gapi.client.gallery.saveSelectedPicture({projectKey:projectKey, image:image, fileId:fileId});
  }


  managerUserFiles(fileId, method, description, fileType, removeFromStorage){
    return gapi.client.gallery.manageUserFiles({fileId:fileId, method:method, description:description, fileType:fileType, removeFromStorage:removeFromStorage});
  }

  getAllFiles(){
    return gapi.client.gallery.getAllFiles({category:'data'});
  }

  addModifyProjectComments(comment) {
    return gapi.client.userService.addModifyProjectComments({'comment':JSON.stringify(comment)});
  }

  getProjectComments(projectId) {
    return gapi.client.userService.getProjectComments({'projectId':projectId});
  }

  recommendUser(userId, recommend, comment):any{
    debugger;
    return gapi.client.userService.recommendUser({userId: userId, recommend: recommend, comment:comment});
  }

  getProjectDetails(key) {
    return gapi.client.gallery.getProject({'projectKey':key});
  }

  acceptProjectComments(id, accepted){
    return gapi.client.userService.acceptProjectComments({'commentId':id, 'accepted':accepted});
  }

}
