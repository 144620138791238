export class ProjectDetails {
  id:number;
  key:string;
  projectName:string;
  technologies:Set<string>;
  projectDescription:string;
  imageUrl:string;
  insights:string;
  pUrl:string;
  otherModules:string;
  learningResources:Set<string>;
  purl:string;
  userlike:boolean;
  likeCount:number;
  resource:string;
  school:string;
  course:string;
  semester:string;
  openDataUrl:string;
  level:string;
  storyUrl:string;
  ipynbFile:boolean;
  privateProject:boolean;
  platforms:Set<string>;
  profileUrl:string;
  selectedImage:number;
  authorId:string;
  editable:boolean;

}


