import { Component, OnInit } from '@angular/core';
import {BackendService} from "../gallery-backend/backend.service";
import {ChangeDetectorRef} from "@angular/core";

@Component({
  selector: 'app-cohort',
  templateUrl: './cohort.component.html',
  styleUrls: ['./cohort.component.css']
})
export class CohortComponent implements OnInit {

  private cohort:any;

  constructor(private service: BackendService, private cd: ChangeDetectorRef) { }

  ngOnInit() {

    if(this.cohort == null)
    {
      this.cohort = {
        semester:'',
        school:'',
        courseId:'',
        students:[],
        student:''
      }
    }
  }

  addStudent(){
    this.cohort.students.push(this.cohort.student);
    this.cd.detectChanges();
  }

  removeStudent(student){
    this.cohort.students.splice(this.cohort.students.indexOf(student), 1);
    this.cd.detectChanges();
  }

  onSubmit() {

      this.service.addModifyCohort(this.cohort).execute((r)=>{
        alert("added your cohort!");
      }, (error) =>{
        alert("Sorry unable to add your project at this time")
      })

  }
}
