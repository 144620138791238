import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../core/user.model';
import {Userprofile} from '../user-profile.model';

import { BackendService } from '../gallery-backend/backend.service';
import {Router, NavigationStart} from '@angular/router';
import {Subscription} from 'rxjs';
import { SessionService } from '../core/session.service';

@Component({
  selector: 'page-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.css']
})
export class UserComponent implements OnInit{

  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;
  busy: Subscription;
  userProfile:Userprofile;
  groupMembers=true;
  userProjects=true;
  yourProjects:string="Projects";
  teamProjects:string="Team Projects";
  userId:string;

  constructor(private session:SessionService, public zone:NgZone, public userService: UserService,
              public authService: AuthService,
              private route: ActivatedRoute,
              private location : Location,
              private fb: FormBuilder, private service: BackendService, private router: Router, private chRef: ChangeDetectorRef ) {


    // this.route.snapshot.paramMap.get("userId")  --this method could be used if component is not reused.

    this.route.paramMap.subscribe(params=>{
      this.userId= params.get("userId");

      if(this.userId){
        if (this.session.apisLoaded) {
          this.getOtherUserProfile(this.userId);
        } else {
          this.busy = this.session.mbccUserService.subscribe(
            () => {
              this.getOtherUserProfile(this.userId);
            });
        }
      }else {
        if (this.session.apisLoaded) {
          this.getUserProfile();
        } else {
          this.busy = this.session.mbccUserService.subscribe(
            () => {
              this.getUserProfile();
            });
        }
      }
    })

  }


  getUserProfile(){
    this.service.getUserProfile().execute((c) => {
      this.userProfile = c.properties;
      this.session.setUserProfile(this.userProfile);
      this.chRef.detectChanges();
    });
  }

  getOtherUserProfile(key){
    this.service.getOtherUserProfile(key).execute((c) => {
      this.userProfile = c.properties;
      this.session.setUserProfile(this.userProfile);
      this.chRef.detectChanges();
    });
  }

  editProfile(){

    this.zone.run(() => {
      this.router.navigate(['/editProfile'])
    });
  }


  showCohortView(){

    this.zone.run(() => {
      this.router.navigate(['/cohort'])
    });
  }

  ngOnInit(): void {
    this.userService.getCurrentUser()
      .then(res => {
        if(res.providerData[0].providerId == 'password'){
          this.user.image = 'http://dsi-vd.github.io/patternlab-vd/images/fpo_avatar.png';
          this.user.name = res.displayName;
          this.user.provider = res.providerData[0].providerId;

        }
        else{
          this.user.image = res.photoURL;
          this.user.name = res.displayName;
          this.user.provider = res.providerData[0].providerId;


        }

      }, err => {
      //  this.router.navigate(['/login']);

      })
  }

  createForm(name) {
    this.profileForm = this.fb.group({
      name: [name, Validators.required ]
    });
  }

  save(value){
    this.userService.updateCurrentUser(value)
      .then(res => {

      }, err => console.log(err))
  }

  exportFeatured(value){
    this.service.exportFeatured()
      .then(res => {

      }, err => console.log(err))
  }


  logout(){
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        console.log("Logout error", error);
      });
  }

  ngOnDestroy() {

    if(this.busy)
      this.busy.unsubscribe();
  }
}
