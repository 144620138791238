import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../core/user.model';
import { BackendService } from '../gallery-backend/backend.service';
import { SessionService } from '../core/session.service';
import {forkJoin} from "rxjs/observable/forkJoin";
import {Router} from "@angular/router";


@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  user: FirebaseUserModel;
  profileForm: FormGroup;
  uid:string=null;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private location : Location,
    private fb: FormBuilder,
    private service: BackendService,
    private session:SessionService,
    private chRef:ChangeDetectorRef,
    private router:Router
  ) {

  }

  ngOnInit(): void {

    this.session.mbccUser$.subscribe((firebaseUser)=>{
      this.user = firebaseUser;
      this.chRef.detectChanges();
    })


    if(this.session.getFirebaseUser() == null)
    this.userService.
    getCurrentUser()
      .then(res => {

        let user = new FirebaseUserModel();
        user.image = res.photoURL;
        user.name = res.displayName;
        user.provider = res.providerData[0].providerId;
        user.email = res.email;

        let makeUserInfoCall = false;
        if(!this.session.getFirebaseUser()) {
          makeUserInfoCall = true;
          this.session.setFirebaseUser(user);
        }


        if(makeUserInfoCall) {

          let userserviceLoaded = () =>
            new Promise(resolve =>
              this.session.userInfoService$.subscribe(() => {
                resolve(true);
              })
            );

          let authCompleted = () =>
            new Promise(resolve =>
              this.session.authTokenSet$.subscribe(() => {
                resolve(true);
              })
            );

          let allServicesLoaded = forkJoin([
            userserviceLoaded(),
            authCompleted()]
          );

          allServicesLoaded.subscribe(val => {

            this.service.setUserInfo(user).execute(() => {
            }, error => {
              console.log("error on user info submit");
            });
          });
        }




      }, err => {


        //  this.router.navigate(['/login']);

      })
  }

  createForm(name) {
    this.profileForm = this.fb.group({
      name: [name, Validators.required ]
    });
  }

  save(value){
    this.userService.updateCurrentUser(value)
      .then(res => {

      }, err => console.log(err))
  }

  logout(){

    this.session.setFirebaseUser(null);
    this.user= null;
    this.chRef.detectChanges();

    this.authService.doLogout()
      .then((res) => {
        this.router.navigate(['/']);
        gapi.auth.setToken(null);
      }, (error) => {
        console.log("Logout error", error);
      });
  }
}
