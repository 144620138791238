export class ProjectComments {
  id:string;
  description:string;
  user:string;
  addedDate:Date;
  modifiedDate:Date;
  feedback:boolean;
  accepted:boolean;
  projectId:string;
  editable:boolean;
  markedForDeletion:boolean;
  userProfileUrl:string;


}
