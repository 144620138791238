import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import {Userprofile} from '../user-profile.model';
import { BackendService } from '../gallery-backend/backend.service';
import {Router, NavigationStart} from '@angular/router';
import {Subscription} from 'rxjs';
import { SessionService } from '../core/session.service';

declare var gapi:any;
declare var places:any;
var placesAutocomplete:any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})

export class UserProfileComponent implements OnInit {

  busy: Subscription;
  locationSelected:boolean=false;


  @ViewChild('userForm', { static: true }) userForm: NgForm;
  availability = [true, false];
  // 0 not provided
  // 1 eligible
  // 2 sponsor required.




//  @Input ()user :Userprofile;
  user = new Userprofile('','','','','','',false,'',0,'','','',null,true, null, null, false);
  save= false;

  constructor( private session:SessionService, private service: BackendService, private router: Router, private chRef: ChangeDetectorRef, ) {

    if (this.session.getUserProfile() == null) {


      this.busy = session.userProfileService$.subscribe(
        () => {
          this.getUserProfile();
        });
    } else {

      this.user = session.getUserProfile();

    }
  }

  getUserProfile(){
    this.service.getUserProfile().execute((c) => {
      this.user = c.properties;

      if(this.user && !this.user.startMonth){

        var dt = new Date();

        this.user.startMonth=dt.getFullYear() +"-" + (dt.getMonth() +1);
      }

      if(!c.properties.hasOwnProperty('showProfilePic')){
        this.user.showProfilePic = true;
      }
      this.chRef.detectChanges();
    });
  }


  ngOnInit() {
    placesAutocomplete = places({
      appId: 'plEDQVFKN8HO',
      apiKey: '39e48468678905362f6bab9bc390c0f8',
      container: document.querySelector('#location'),
      type:'city'
    });


  }

  onChange(event){

    if(!event.target.value){
      this.user.location="";
    } else {
      this.user.location=event.target.value;
    }


  }


  onClose(){
    this.save =false;
  }
  onSubmit() {

    this.save = true;

    this.service.updateUserProfile(this.user).execute((r)=>{
      alert("updated your profile!");
      this.router.navigate(['/user']);
    }, (error) =>{
      alert("Sorry unable to add your project at this time")
    })

  }

  // controller
  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    if(this.busy)
      this.busy.unsubscribe();
  }
}
