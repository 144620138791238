import { Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {StoryService} from "../gallery-backend/story.service";
import { DomSanitizer } from '@angular/platform-browser';
import { BackendService } from '../gallery-backend/backend.service';

@Component({
  selector: 'picture-change',
  templateUrl: './picture-change-component.html',
  styleUrls: ['./picture-change-component.css']
})
export class PictureChangeComponent {

  images: Array<string>=[];

  private fileId: string;


  @Input('projectKey')
  projectKey:string;

  @Input('storyUrl')
  storyUrl:string;

  @Input('imageSrc')
  imageSrc:string;

  @Input('editable')
  editable:boolean;

  newImageSrc:string

  selectedImage:number;

  showButton:boolean = true;

  showComponent:boolean=false;

  showExternal:boolean=false;


  constructor(private service: BackendService,private cd: ChangeDetectorRef,private storyService: StoryService, private _DomSanitizationService: DomSanitizer ) {



  }



  getStoryImages(){

  if(!this.storyUrl || this.images.length > 0)
    return;



  this.fileId = this.storyUrl.substring(this.storyUrl.lastIndexOf('/')+1);

  var storyUrlApi = 'https://www.googleapis.com/storage/v1/b/eda-stories/o/'+ this.fileId + '?alt=media';

  //   var storyUrlApi = 'https://www.googleapis.com/download/storage/v1/b/eda-stories/o/12rErBCYMD_s9Y-_EUUVYWM5EWX0zIkEq.html?alt=media';


   // var storyUrlApi = 'https://www.googleapis.com/download/storage/v1/b/eda-stories/o/1luff10HH1fcN7jdnjoWGBfKwxMbOZfWQ.html?alt=media';


    this.storyService.getStory(storyUrlApi)
      .subscribe((data) => {
        var doc = new DOMParser().parseFromString(data, "text/html");

        var images = doc.getElementsByTagName('img');

        for (var i = 0; i < images.length; i++) {
          this.images.push(images[i].attributes[0].nodeValue.trim());
        }

        if(!this.imageSrc.startsWith("https://storage.googleapis.com/eda-pictures"))
          this.images.push(this.imageSrc);

        this.cd.detectChanges();

      });

  }


  selectPicture(index){

    this.imageSrc=this.images[index];
    this.selectedImage = index;
    this.cd.detectChanges();
  }


  changePictureMain(){
    this.getStoryImages();
    this.showButton = false;
    this.showComponent=true;
    this.cd.detectChanges();
  }

  addExternalImage(){
    this.images.push(this.newImageSrc);
    this.cd.detectChanges();
  }

  saveSelection(){

    this.showButton = true;
    this.showComponent=false;

    this.cd.detectChanges();

    var newImage = ''+this.selectedImage;
    if(!this.images[this.selectedImage].startsWith("data:image/")){
      newImage= this.images[this.selectedImage];
    }

    if(this.fileId)
      this.fileId=this.fileId.substring(0,this.fileId.length-5);

    this.service.saveSelectedPicture(this.projectKey,newImage, this.fileId).then((resp) => {
      alert("Your picture is changed. Please allow at least 24 hrs to see your update")
    });
  }


  showExternalImage(){
    this.showExternal=true;
    this.cd.detectChanges();
  }
}
