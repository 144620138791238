import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { UserService } from '../core/user.service';
import { FirebaseUserModel } from '../core/user.model';
import {SessionService} from "../core/session.service";

@Injectable()
export class UserResolver implements Resolve<FirebaseUserModel> {

  constructor(public userService: UserService, private router: Router, private session:SessionService) { }

  resolve(route: ActivatedRouteSnapshot) : Promise<FirebaseUserModel> {

    let user = new FirebaseUserModel();

    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
        .then(res => {

          if(res.providerData[0].providerId == 'password'){
            user.image = 'http://dsi-vd.github.io/patternlab-vd/images/fpo_avatar.png';
            user.name = res.displayName;
            user.provider = res.providerData[0].providerId;
            return resolve(user);
          }
          else{

            user.image = res.photoURL;
            user.name = res.displayName;
            user.provider = res.providerData[0].providerId;

            return resolve(user);
          }


        }, err => {
            this.router.navigate(['/']);
          return reject(err);
        })
    })
  }
}
